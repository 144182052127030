import styled from 'styled-components'
export const IndexStyle = styled.div`
  width: 100%;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font: 'PingFang SC-Bold', 'PingFang SC', 'PingFang SC-Medium', Helvetica, Arial, 'Microsoft Yahei',
      'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
    color: #333;
  }
  html,
  body,
  #app {
    background-color: white;
    color: white;
    font-size: 10px;
    font-family: 'PingFang SC-Medium';
    text-align: start;
  }

  ul,
  ol {
    list-style: none;
  }

  em,
  i {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: #333;
  }

  img {
    vertical-align: middle;
  }

  input {
    border: none;
    outline: none;
    color: #333;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .clearfix::before,
  .clearfix::after {
    content: '';
    display: block;
  }
  .clearfix::after {
    clear: both;
  }

  .text-start {
    text-align: start;
  }

  .FLEXC {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .TEXTLinear {
    color: #25edd5;
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .PUBW {
    width: 87%;
    margin: 0 auto;
  }
  .TEXTLinear {
    color: #25edd5;
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .outBox {
    width: 100%;
    height: 100%;
  }

  .Main {
    width: 100%;
    background-image: url('/images/assets/homeyidond/yondonduan@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .app1 {
      width: 100%;
      height: 259px;
      background-image: url('/images/assets/homeyidond/app1@2x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 30px;

      .app_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        margin-top: 60px;
        margin-left: 15px;
      }

      .app_2 {
        font-size: 15px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 15px;
        margin-left: 15px;

        width: 500px;
      }

      .app_3 {
        display: inline-block;
        .app_3111 {
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url('/images/assets/homeyidond/app2@2x.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          margin-top: 10px;
          margin-left: 15px;
          padding: 10px;
          span {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #081025;
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 12px;
          }
        }
      }
    }

    .app_4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 94%;
      margin: 0 auto 0;

      img {
        width: 140px;
        height: 154px;
      }

      p {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .app_5 {
      width: 90%;
      margin: 0 auto 0;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 200%;
    }

    .app_6 {
      width: 90%;
      height: 32.5px;
      background: rgba(46, 230, 216, 0.37);
      border-radius: 30px;
      opacity: 1;
      display: flex;
      align-items: center;
      margin: 22px auto 0;

      p:nth-child(1) {
        background-image: url('/images/assets/homeyidond/app5.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 70px;
        height: 32.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #000;
      }

      p:nth-child(2) {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        margin-left: 8px;
        width: 70%;
        overflow: hidden;
        height: 40px;
        line-height: 40px;
      }
    }

    .app6_top {
      margin-top: 13px !important;
    }

    .app_7 {
      margin: 67px auto 0;
      text-align: center;

      p:nth-child(1) {
        font-size: 18px;
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
      }

      p:nth-child(2) {
        font-size: 14px;
        margin: 14px auto 0;
        text-align: left;
        width: 90%;
        color: #fff;
        line-height: 200%;
      }
    }

    .app_8 {
      display: flex;
      width: 90%;
      margin: 10px auto 0;
      justify-content: space-between;
      align-items: center;

      img {
        width: 205px;
        height: 173px;
      }

      .app8_da {
        .app8_1 {
          width: 120px;
          margin-bottom: 11px;
          height: 49px;
          border-radius: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: flex-start;
          border: 0.1875rem solid;
          border-image: linear-gradient(-270deg, #25edd5, rgba(157, 128, 254, 0)) 3 3;
          // margin-left: 80px;
          position: relative;
          right: 40px;

          img {
            width: 34px;
            height: 34px;
            margin-left: 12px;
            margin-right: 12px;
          }

          p {
            color: #fff;
            font-size: 12px;
          }
        }

        .app8_left {
          position: relative;
          left: 0px;
          // width: 200px;
          // margin-left: 80px;
        }
      }
    }

    .app9_hd {
      margin-top: 24px;
      margin-left: 63px;
    }

    .app9_hd1 {
      margin-top: 24px;
      margin-right: 63px;
    }

    .app_9 {
      width: 90%;
      margin: 31px auto 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .app9_1 {
        width: 30%;
        text-align: center;

        .app9_2 {
          text-align: center;
          color: #fff;
          font-size: 12px;
        }

        .app9_3 {
          margin-top: 14px;
          background-image: url('/images/assets/homeyidond/app9_img1@2x.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 108px;
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            width: 60px;
            font-size: 12px;
            // height: 110px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            color: #fff;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 超出几行省略 */
          }
        }

        .app9_31 {
          background-image: url('/images/assets/homeyidond/app9_img2@2x.png');
        }

        .app9_32 {
          background-image: url('/images/assets/homeyidond/app9_img3@2x.png');
        }

        .app9_33 {
          background-image: url('/images/assets/homeyidond/app9_img4@2x.png');
        }

        .app9_34 {
          background-image: url('/images/assets/homeyidond/app9_img5@2x.png');
        }
      }
    }

    .app_10 {
      width: 90%;
      margin: 74px auto 0;

      p:nth-child(1) {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-weight: bold;
      }

      p:nth-child(2) {
        margin-top: 28px;
        line-height: 170%;
        font-size: 14px;
        color: #fff;
      }
    }

    .app_11 {
      width: 90%;
      margin: 62px auto 0;
      display: flex;
      justify-content: space-between;

      .app11_1 {
        p:nth-child(1) {
          display: inline-block;
          background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 12px;
          margin-bottom: 20px;
        }

        p {
          display: flex;
          align-items: center;
          margin-top: 16px;

          img {
            width: 12px;
            height: 12px;
          }

          span {
            color: #fff;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }

      .app11_2 {
        margin-top: 10px;
        position: absolute;
        right: 0;

        img {
          width: 224px;
          height: 224px;
        }
      }
    }

    .app_12 {
      width: 94%;
      margin: 53px auto 0;
      text-align: center;

      .app12_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        margin-bottom: 18px;
        font-weight: bold;
      }

      .app12_2 {
        width: 95%;
        margin: 0 auto;
        // height: 228px;
        padding: 5px 0;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 12px;
        opacity: 1;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;

        img {
          width: 110px;
          height: 97px;
          margin-left: 4px;
        }

        .app12_3 {
          text-align: left;

          .app12_4 {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .app12_5 {
            width: 72px;
            height: 2px;
            background: linear-gradient(to right, #25edd5, #9d80fe);
            margin-top: 8px;
          }

          .app12_6 {
            margin-top: 9px;
            font-size: 12px;
            width: 236px;
            color: #fff;
            // height: 60px;
            // overflow: hidden;
          }
        }

        .app12_7 {
          display: flex;
          margin-top: 10px;
          flex-wrap: wrap;
          p {
            display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 12px;
            margin-right: 14px;
          }

          p:nth-child(4) {
            margin-right: 0;
          }
        }
      }

      .topapp12 {
        margin-top: 30px;
      }
    }

    .app_13 {
      margin: 64px auto 0;
      width: 94%;

      .app13_1 {
        display: flex;

        .app13_2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          img {
            width: 166px;
            height: 123px;
          }

          .app13_3 {
            .app13_4 {
              p:nth-child(1) {
                font-size: 14px;
                color: #fff;

                span {
                  width: 16px;
                  height: 16px;
                  background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
                  display: inline-block;
                  border-radius: 50%;
                  margin-right: 10px;
                }
              }

              p:nth-child(2) {
                margin-left: 13px;
                margin-top: 10px;

                span {
                  display: inline-block;
                  background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 14px;
                }

                span:nth-child(2) {
                  margin-left: 6px;
                }
              }

              p:nth-child(3) {
                margin-left: 13px;
                margin-top: 10px;

                span {
                  display: inline-block;
                  background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 12px;
                }

                span:nth-child(2) {
                  margin-left: 6px;
                }
              }
              p:nth-child(4) {
                margin-left: 12px;
                margin-top: 10px;

                span {
                  display: inline-block;
                  background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-size: 12px;
                }

                span:nth-child(2) {
                  margin-left: 6px;
                }
              }
            }

            .app134_top {
              margin-top: 9px;
            }
          }
        }
      }

      .app13_5 {
        margin-top: 16px;

        p:nth-child(1) {
          font-size: 14px;
          color: #fff;
          span {
            width: 16px;
            height: 16px;
            background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        p:nth-child(2) {
          margin-left: 13px;
          margin-top: 10px;

          span {
            display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 24px;
          }

          span:nth-child(2) {
            margin-left: 6px;
          }
        }

        p:nth-child(3) {
          margin-left: 26px;
          margin-top: 20px;

          span {
            display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 24px;
          }

          span:nth-child(2) {
            margin-left: 6px;
          }
        }
      }
    }

    .app_14 {
      text-align: center;
      margin: 128px auto 0;
      width: 94%;

      .app14_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        font-weight: bold;
      }

      .app14_2 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .app14_3 {
          width: 31%;
          // height: 132px;
          padding: 0px 0 10px 0;
          background-image: url('/images/assets/homeyidond/app14_3@2x.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-top: 44px;

          img {
            width: 50px;
            height: 50px;
            margin-top: -25px;
          }

          p:nth-child(2) {
            // display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 14px;
          }

          p:nth-child(3) {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }

    .app_15 {
      text-align: center;
      margin: 83px auto 0;
      width: 94%;
      height: 4%;
      .app15_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        font-weight: bold;
      }

      .app15_2 {
        position: relative;
        margin-top: 42px;

        .app15_3 {
          text-align: center;
          width: 121px;
          position: absolute;
          left: 0%;
          top: 160px;
          p:nth-child(1) {
            background-image: url('/images/assets/homeyidond/app15_3@2x.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 75px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 0;

            span {
              font-size: 14px;
              font-weight: bold;
              background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          p:nth-child(2) {
            height: 62px;
            width: 2px;
            background: linear-gradient(to bottom, #25edd5, #9d80fe);
            margin: 0 auto 0;
            border-right: 0.0625rem dashed #25edd5;
            height: 8.8125rem;
            margin-bottom: 10px;
          }

          p:nth-child(3) {
            margin-top: 12px;
            font-size: 14px;
            color: #fff;
          }

          p:nth-child(4) {
            margin-top: 4px;
            font-size: 14px;
            display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .app15_4 {
          text-align: center;
          width: 128px;
          position: absolute;
          left: 20%;
          p:nth-child(4) {
            background-image: url('/images/assets/homeyidond/app15_3@2x.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 78px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 0;
            color: #fff;

            span {
              color: #fff;
              font-weight: bold;
              background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          p:nth-child(3) {
            height: 62px;
            width: 2px;
            background: linear-gradient(to bottom, #25edd5, #9d80fe);
            margin: 0 auto 0;
            border-right: 0.0625rem dashed #25edd5;
            height: 8.8125rem;
            margin-top: 10px;
          }

          p:nth-child(1) {
            margin-top: 12px;
            font-size: 14px;
            color: #fff;
          }

          p:nth-child(2) {
            margin-top: 4px;
            font-size: 14px;
            display: inline-block;
            background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .app15_leftd {
          left: 42%;
          top: 80px;
          color: #fff;
        }
        .app15_4left {
          left: 65%;
          top: -15px;
          color: #fff;
        }
      }
    }
    .app_16 {
      text-align: center;
      margin: 750px auto 0;
      width: 94%;
      .app16_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 38px;
      }
      .app16_2 {
        display: flex;
        align-items: center;
        justify-content: center;
        .app16_3 {
          width: 100px;
          height: 24px;
        }
        .app16_4 {
          width: 120px;
          height: 26px;
          margin-left: 92px;
        }
      }
      .app16_5 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 25%;
          text-align: center;
          margin-top: 50px;
        }
        .app16_6 {
          width: 88px;
          height: 24px;
        }
        .app16_7 {
          width: 92px;
          height: 24px;
        }
        .app16_8 {
          width: 58px;
          height: 28px;
        }
        .app16_9 {
          width: 134px;
          height: 28px;
        }
        .app16_10 {
          width: 70px;
          height: 26px;
        }
        .app16_11 {
          width: 88px;
          height: 26px;
        }
        .app16_12 {
          width: 58px;
          height: 26px;
        }
        .app16_13 {
          width: 134px;
          height: 26px;
        }
        .app16_14 {
          width: 74px;
          height: 26px;
        }
        .app16_15 {
          width: 78px;
          height: 26px;
        }
        .app16_16 {
          width: 150px;
          height: 32px;
        }
        .app16_17 {
          width: 56px;
          height: 28px;
        }
      }
    }
    .app_17 {
      text-align: center;
      margin: 82px auto 0;
      width: 100%;
      .app17_1 {
        display: inline-block;
        background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 36px;
      }
      .scrollList {
        height: 120px;
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        margin-top: 25px;
        padding-top: 40px;
        .swiper {
          display: flex;
          img {
            height: 44px;
            width: auto;
            margin-right: 150px;
          }
        }
      }
    }
    .app_18 {
      width: 100%;
      // height: 212px;
      background-color: #1d1d1d;
      margin-top: 120px;
      text-align: center;
      padding-bottom: 28px;
      padding-top: 20px;
      .app18_1 {
        width: 170px;
        height: 44px;
        margin-top: 30px;
      }
      .footer_1 {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 57px;
          height: 51px;
        }
        p {
          // margin-top: 43px;
          margin-top: 0;
          margin-left: 16px;
          font-size: 24px;
          background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
        }
      }
      .app18_2 {
        margin-top: 18px;
        font-size: 14px;
        color: #fff;
      }
      .app18_3 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
        .app18_4 {
          width: 42px;
          height: 42px;
          margin-right: 14px;
        }
      }
    }
  }
`
