import styled from 'styled-components'
export const IndexStyled = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font: 'PingFang SC-Bold', 'PingFang SC', 'PingFang SC-Medium', Helvetica, Arial, 'Microsoft Yahei',
      'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
    color: #333;
  }
  html,
  body,
  #app {
    background-color: white;
    color: white;
    font-size: 10px;
    font-family: 'PingFang SC-Medium';
    text-align: start;
  }

  ul,
  ol {
    list-style: none;
  }

  em,
  i {
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: #333;
  }

  img {
    vertical-align: middle;
  }

  input {
    border: none;
    outline: none;
    color: #333;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .clearfix::before,
  .clearfix::after {
    content: '';
    display: block;
  }
  .clearfix::after {
    clear: both;
  }

  .text-start {
    text-align: start;
  }

  .FLEXC {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .TEXTLinear {
    color: #25edd5;
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .PUBW {
    width: 87%;
    margin: 0 auto;
  }
  .TEXTLinear {
    color: #25edd5;
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .outBox {
    width: 100%;
    height: 100%;
    color: #fff;
  }

  .Main {
    width: 100%;
    background-image: url('/images/assets/homeyidond/yondonduan@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    color: #fff;

    .home_ixant {
      width: 100%;
      height: 1;
      border-bottom: 1px solid #5a5a5a;
    }
    .noright {
      display: flex;
      justify-content: flex-end;
      height: 1120px;

      div {
        width: 94%;
        height: 100%;
        background-image: url('/images/assets/home/phone.png');
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        padding-top: 324px;

        h1 {
          font-size: 100px;
          font-weight: 400;
          display: inline-block;
          font-family: '汉仪菱心体简-regular', '汉仪菱心体简';
          background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-weight: 900;
        }

        h2 {
          font-size: 64px;
          font-weight: 400;
          margin-top: 20px;
          width: 1000px;
          color: #fff;
        }

        .dddddd {
          display: flex;
          border-radius: 5px;
          margin-top: 30px;
          font-size: 24px;
          color: black;
          cursor: pointer;
          &:first-of-type {
            margin-top: 77px;
          }
          .ddd11 {
            background-image: url('/images/assets/home/enter.png');
            background-size: 100% 100%;
            padding: 10px 20px;
          }

          span {
          }
          img {
            width: 24px;
            margin-left: 10px;
            height: 24px;
          }
        }
      }
    }

    .Content {
      text-align: center;

      h1 {
        font-size: 72px;
        font-weight: 400;
        background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block;
        text-align: center;
      }

      > p {
        font-size: 28px;
        text-align: start;
        font-weight: 400;
        margin-top: 68px;
        line-height: 50px;
        letter-spacing: 1px;
        color: #fff;
      }

      .intruduce {
        display: flex;
        justify-content: space-between;
        margin-top: 72px;

        img {
          width: 575px;
          height: 596px;
        }

        ul {
          display: flex;
          flex-direction: column;
          padding-top: 105px;

          li {
            width: 956px;
            display: flex;
            align-items: center;
            margin-bottom: 31px;
            height: 58px;
            background: rgba(46, 230, 216, 0.37);
            border-radius: 62px 62px 62px 62px;

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 125px;
              height: 58px;
              font-size: 20px;
              color: black;
              border-radius: 62px;
              background: linear-gradient(160deg, #25edd5, #9d80fe);
            }

            p {
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              font-size: 20px;
              font-family: 'PingFang SC-Bold', 'PingFang SC';
              margin-left: 19px;
              display: inline-block;
              text-align: left;
              width: 90%;
            }
          }
        }
      }
    }

    .aqua {
      display: flex;
      margin-top: 367px;

      .aquaRight {
        width: 671px;

        h1 {
          background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-size: 72px;
          letter-spacing: 2px;
          font-weight: 400;
        }

        p {
          margin-top: 140px;
          font-size: 30px;
          color: #ffffff;
          line-height: 50px;
          letter-spacing: 1px;
          width: 660px;
          height: 500px;
          overflow: hidden;
          text-overflow: ellipsis;
          // white-space:nowrap;
          i {
            color: #2ee6d8;
          }
        }
      }

      .aquaLeft {
        display: flex;
        align-items: center;
        background-image: url('/images/assets/home/aquaItemBg.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;

        img {
          width: 575px;
          height: 463px;
        }

        ul {
          margin-left: 41px;

          li {
            width: 335px;
            margin-bottom: 68px;
            height: 136px;
            border-radius: 5px 5px 5px 5px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: flex-start;
            border: 3px solid;
            border-image: linear-gradient(
                -270deg,
                rgba(37.00000159442425, 237.0000010728836, 213.00000250339508, 1),
                rgba(157.0000058412552, 128.0000075697899, 254.00000005960464, 0)
              )
              3 3;

            img {
              width: 94px;
              height: 97px;
              margin-left: 16px;
            }

            p {
              font-size: 28px;
              margin-left: 18px;
            }

            &:last-child {
              margin-bottom: 0;
              margin-left: -104px;
            }

            &:first-child {
              margin-left: -104px;
            }
          }
        }
      }
    }
  }

  .Main2 {
    background-image: url('/images/assets/home/mainBg2.png');
    background-size: cover;
    width: 100%;
    // height: 3292px;
    margin-top: -2px;

    .support {
      width: 87%;
      margin: 0 auto;
      text-align: center;

      h2 {
        font-weight: 400;
        font-size: 72px;
        display: inline-block;
        letter-spacing: 2px;
        background: linear-gradient(to right, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 96px;
      }

      ul {
        display: flex;
        margin-top: 130px;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          p {
            font-size: 24px;
            font-weight: 400;
            color: #ffffff;
          }

          span {
            display: block;
            width: 308px;
            padding: 99px 75px 50px 76px;
            height: 302px;
            margin-top: 23px;
            background-image: url('/images/assets/home/supportItem1.png');
            background-size: cover;
            p {
              overflow: hidden;
              width: 140px;
              height: 130px;
            }
          }

          &:nth-child(2) {
            span {
              background-image: url('/images/assets/home/supportItem2.png');
            }
          }

          &:nth-child(3) {
            span {
              background-image: url('/images/assets/home/supportItem3.png');
            }
          }
        }

        &:last-child {
          margin-top: 52px;
          justify-content: center;

          li {
            &:first-child {
              margin-right: 283px;
            }

            &:nth-child(1) {
              span {
                background-image: url('/images/assets/home/supportItem4.png');
              }
            }

            &:nth-child(2) {
              span {
                background-image: url('/images/assets/home/supportItem5.png');
              }
            }
          }
        }
      }
    }

    .partner {
      width: 87%;
      margin: 0 auto;
      margin-top: 250px;
      text-align: center;

      h1 {
        font-size: 72px;
        font-weight: 400;
        background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block;
        text-align: center;
      }

      > p {
        font-size: 28px;
        text-align: start;
        font-weight: 400;
        margin-top: 68px;
        line-height: 50px;
        letter-spacing: 1px;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 19px 49px 0 55px;
        justify-content: space-between;

        img {
          width: 798px;
          height: 742px;
        }

        span {
          display: flex;
          flex-direction: column;
          text-align: start;

          h4 {
            font-size: 32px;
            font-weight: 400;
            display: inline-block;
            margin-bottom: 50px;
            letter-spacing: 1px;
            background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
          }

          p {
            text-indent: 50px;
            display: inline-block;
            font-size: 28px;
            margin-bottom: 29px;
            background-image: url('/images/assets/home/partnerTips.png');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 31px 31px;
          }
        }
      }
    }

    .technology {
      width: 87%;
      margin: 0 auto;
      margin-top: 100px;
      text-align: center;

      h1 {
        font-size: 72px;
        font-weight: 400;
        background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block;
        margin-bottom: 150px;
        text-align: center;
      }

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 540px;
          height: 220px;
          background: rgba(255, 255, 255, 0.08);
          border-radius: 43px 43px 43px 43px;
          opacity: 1;
          border: 1px solid rgba(255, 255, 255, 0.08);
          border-top: 1px solid #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 43px 0px 43px;
          &:nth-of-type(4) {
            margin-top: 120px;
          }
          &:nth-of-type(5) {
            margin-top: 120px;
          }
          &:nth-of-type(6) {
            margin-top: 120px;
          }
          img {
            width: 199px;
            height: 168px;
            margin-top: -90px;
            margin-bottom: 30px;
          }

          h3 {
            font-size: 32px;
            margin-bottom: 40px;
            font-weight: normal;
          }

          span {
            margin-bottom: 40px;
            width: 311px;
            border: 1px solid;
            border-image: linear-gradient(
                160deg,
                rgba(37.00000159442425, 237.0000010728836, 213.00000250339508, 1),
                rgba(157.0000058412552, 128.0000075697899, 254.00000005960464, 1)
              )
              1 1;
          }

          > p {
            font-size: 25px;
            text-align: start;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 超出几行省略 */
            height: 100px;
          }

          div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            > p {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 45%;
              margin-top: 20px;
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              font-size: 26px;
            }
          }
        }
      }
    }
  }

  .Main3 {
    background-image: url('/images/assets/home/mainBg3.png');
    background-size: cover;
    width: 100%;
    text-align: center;
    margin-top: -2px;
    padding-top: 120px;
    padding-bottom: 100px;

    .CNScoin {
      text-align: center;
      width: 87%;
      margin: 0 auto;

      h1 {
        font-weight: 400;
        font-size: 72px;
        display: inline-block;
        letter-spacing: 2px;
        background: linear-gradient(to right, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      & > p {
        font-size: 28px;
        text-align: start;
        font-weight: 400;
        margin-top: 68px;
        line-height: 50px;
        letter-spacing: 1px;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 88px;
        align-items: flex-start;
        justify-content: space-between;

        ul {
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            align-items: center;
            font-size: 36px;
            text-align: start;
            margin-bottom: 35px;

            &::before {
              content: '';
              display: inline-block;
              width: 21px;
              height: 21px;
              margin-right: 15px;
              border-radius: 50%;
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              opacity: 1;
            }
          }
          & > div {
            display: flex;
            flex-wrap: wrap;
            margin-left: 21px;
            margin-bottom: 20px;
            & > p {
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              font-size: 36px;
              display: inline-block;
              font-weight: 400;
              margin-bottom: 30px;
              margin-right: 84px;
            }
          }
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          img {
            width: 818px;
            height: 608px;
          }
          .cncoin_p1 {
            font-weight: 500;
            font-size: 36px;
            color: #ffffff;
            position: absolute;
            top: 200px;
            left: 200px;
          }
          .cncoin_p2 {
            font-weight: 500;
            font-size: 36px;
            color: #ffffff;
            position: absolute;
            top: 540px;
            right: 200px;
          }
        }
      }
    }
    .zoology {
      width: 87%;
      margin: 0 auto;
      margin-top: 200px;
      text-align: right;
      h1 {
        font-weight: 400;
        font-size: 72px;
        display: inline-block;
        letter-spacing: 2px;
        background: linear-gradient(to right, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 109px;
        justify-content: space-between;
        li {
          margin-bottom: 55px;
          background-image: url('/images/assets/home/zoologyLi.png');
          width: 460px;
          background-repeat: no-repeat;
          height: 181px;
          padding: 0px 17px 0 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: flex-start;
          background-size: 460px 181px;
          img {
            width: 150px;
            height: 150px;
          }
          > span {
            margin-left: 23px;
            p {
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              text-align: start;
              font-size: 32px;
            }
            h5 {
              margin-top: 10px;
              text-align: start;
              font-size: 32px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .roadMap {
      width: 87%;
      margin: 0 auto;
      margin-top: 240px;
      text-align: center;
      h1 {
        font-weight: 400;
        font-size: 72px;
        display: inline-block;
        letter-spacing: 2px;
        background: linear-gradient(to right, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      ul {
        display: flex;
        margin-top: 137px;
        align-items: flex-start;
        justify-content: space-between;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          span {
            display: block;
            width: 263px;
            height: 108px;
            line-height: 108px;
            font-size: 35px;
            position: relative;
            background-image: url('/images/assets/home/roadMapLi.png');
            background-size: 100% 100%;
            > p {
              background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
            }
            &::after {
              content: '';
              display: block;
              border-right: 1px dashed rgba(37, 237, 213, 1);
              height: 141px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          > p {
            font-size: 24px;
            width: 356px;
            margin-top: 244px;
          }
          i {
            background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: 356px;
            margin-top: 14px;
            font-size: 24px;
          }
          &:nth-child(1) {
            margin-top: 80px;
          }
          &:nth-child(2) {
            margin-top: 201px;
          }
          &:nth-child(4) {
            margin-top: 135px;
          }
        }
      }
    }
    .shareholder {
      text-align: center;
      margin-top: 200px;
      h4 {
        background: linear-gradient(160deg, #25edd5 0%, #9d80fe 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block;
        font-size: 40px;
      }
      .major {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 86px;
        img {
          width: 222px;
          height: 100%;
          &:last-child {
            width: 268px;
            margin-left: 210px;
          }
        }
      }
      .secMajor {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 87%;
        margin: 0 auto;
        flex-wrap: wrap;
        margin-top: 85px;
        justify-content: space-between;

        img {
          width: auto;
          height: 44px;
          &:nth-child(6) {
            margin-right: 0;
          }
          &:nth-child(12) {
            margin-right: 0;
          }
        }
      }
      .report {
        margin-top: 164px;
      }
      .scrollList {
        height: 165px;
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        margin-top: 55px;
        padding-top: 60px;
        .swiper {
          display: flex;
          img {
            height: 44px;
            width: auto;
            margin-right: 150px;
          }
        }
      }
      .footer {
        width: 87%;
        margin: 0 auto;
        margin-top: 152px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          text-align: start;
          .footer_1 {
            display: flex;
            align-items: center;
            img {
              width: 57px;
              height: 51px;
            }
            p {
              // margin-top: 43px;
              margin-top: 0;
              margin-left: 16px;
              font-size: 24px;
              background-image: -webkit-linear-gradient(left, #25edd5, #9d80fe);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: bold;
            }
          }
          p {
            margin-top: 43px;
            // margin-left: 16px;
            font-size: 24px;
          }
        }

        span {
          display: flex;

          img {
            width: 51px;
            height: 51px;
            margin-left: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
`
