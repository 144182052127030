import { IndexStyled } from './PcStyle'
import { useTranslation } from 'contexts/Localization'
import { useRouter } from 'next/router'

const IndexPc = () => {
  const { t } = useTranslation()
  const twitter = () => {
    window.location.href = 'https://twitter.com/CoinNexus1986'
  }
  const medium = () => {
    window.location.href = 'https://medium.com/@coinnexusofficial'
  }
  const feiji = () => {
    window.location.href = 'https://t.me/CoinNexus1'
  }
  const router = useRouter()

  const navigate_to = (type: any) => {
    switch (type) {
      case 1:
        router.push('/swap')
        break
      case 2:
        window.location.href = 'https://scan.coinnexus.vip'
        break
      case 3:
        window.location.href = 'https://cnsbridge.coinnexus.vip'
        break
    }
  }

  return (
    <IndexStyled>
      <div className="outBox">
        <div className="Main">
          <div className="noright">
            <div>
              <h1 className="TEXT">COINNEXUS</h1>
              <h2>{t('ylwebjryyz')}</h2>
              <span className="dddddd" onClick={() => navigate_to(1)}>
                <span className="ddd11">
                  <span>{t('dianjjinruswap')}</span>
                  <img src="/images/assets/home/enterPoint.png" alt="" />
                </span>
              </span>
              <span className="dddddd" onClick={() => navigate_to(2)}>
                <span className="ddd11">
                  <span>{t('dianjjinrucns')}</span>
                  <img src="/images/assets/home/enterPoint.png" alt="" />
                </span>
              </span>
              <span className="dddddd" onClick={() => navigate_to(3)}>
                <span className="ddd11">
                  <span>{t('dianjijinrukuanlq')}</span>
                  <img src="/images/assets/home/enterPoint.png" alt="" />
                </span>
              </span>
            </div>
          </div>
          <div className="Content PUBW">
            <h1>{t('coinjianjie')}</h1>
            <p>{t('coinqulkjinr')}</p>
            <div className="intruduce">
              <img src="/images/assets/home/intruduce.png" alt="" />
              <ul>
                <li>
                  <span>{t('dinwei')}</span>
                  <p>{t('ytweb3jrshdjv')}</p>
                </li>
                <li>
                  <span>{t('smin')}</span>
                  <p>{t('wjrjtggx')}</p>
                </li>
                <li>
                  <span>{t('yjing')}</span>
                  <p>{t('dazxnwxsfw')}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="aqua PUBW">
            <div className="aquaRight">
              <h1>{t('aqjjh')}</h1>
              <p>
                {t('sypc1')} <i>{t('sypc2')}</i>,<i>{t('sypc3')}</i>,<i>{t('sypc4')}</i>
                {t('sypc5')}
                <i>{t('sypc6')}</i>
                {t('sypc7')}
              </p>
            </div>
            <div className="aquaLeft">
              <img src="/images/assets/home/aqua.png" alt="" />
              <ul>
                <li>
                  <img src="/images/assets/home/aquaItem1.png" alt="" />
                  <p>{t('kjill')}</p>
                </li>
                <li>
                  <img src="/images/assets/home/aquaItem2.png" alt="" />
                  <p>{t('qkl')}</p>
                </li>
                <li>
                  <img src="/images/assets/home/aquaItem3.png" alt="" />
                  <p>{t('lzjs')}</p>
                </li>
                <li>
                  <img src="/images/assets/home/aquaItem4.png" alt="" />
                  <p>{t('rgzn')}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="Main2">
          <div className="support">
            <h2>{t('jmizczc')}</h2>
            <ul>
              <li>
                <p>Andreessen Horowitz</p>
                <span>
                  <p>{t('sjszda')}</p>
                </span>
              </li>
              <li>
                <p>TechConnect Partners</p>
                <span>
                  <p>{t('lingxdjjcx')}</p>
                </span>
              </li>
              <li>
                <p>Blockchain Capital</p>
                <span>
                  <p>{t('wymgjjs')}</p>
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <p>Digital Assets Capital</p>
                <span>
                  <p>{t('zzuszzcly')}</p>
                </span>
              </li>
              <li>
                <p>Innovation Ventures</p>
                <span>
                  <p>{t('zzyyklxm')}</p>
                </span>
              </li>
            </ul>
          </div>
          <div className="partner">
            <h1>{t('coinsdzlhz')}</h1>
            <p>{t('xjpsxjpzzf')}</p>
            <div>
              <span>
                <h4>{t('kejiyjr')}</h4>
                <p>{t('jlpzfw')}</p>
                <p>{t('kjinzfhhk')}</p>
                <p>{t('kjingduobzjy')}</p>
                <p>{t('szzfkfh')}</p>
                <p>{t('szsfyz')}</p>
                <p>{t('jinrnftpt')}</p>
                <p>{t('zhinenghydk')}</p>
              </span>
              <img src="/images/assets/home/partner.png" alt="" />
            </div>
          </div>
          <div className="technology">
            <h1>{t('hxjs')}</h1>
            <ul>
              <li>
                <img src="/images/assets/home/technology1.png" alt="" />
                <h3>CM Virtual Machine</h3>
                <span></span>
                {/* <p>{t("cposgsjzsjypos")}</p> */}
                {/* <div>
                  <p>{t("gx")}</p>
                  <p>{t("aq")}</p>
                  <p>{t("zy")}</p>
                  <p>{t("cx")}</p>
                </div> */}
              </li>
              <li>
                <img src="/images/assets/home/technology2.png" alt="" />
                <h3>CoinNexus SDK</h3>
                <span></span>
                {/* <p>{t("home1")}</p>
                <div>
                  <p>{t("home2")}</p>
                  <p>{t("sy1")}</p>
                  <p>{t("sy2")}</p>
                </div> */}
              </li>
              <li>
                <img src="/images/assets/home/technology3.png" alt="" />
                <h3>{t('gonshi')}</h3>
                <span></span>
              </li>
              <li>
                <img src="/images/assets/home/technology4.png" alt="" />
                <h3>{t('gongshi1')}</h3>
                <span></span>
              </li>
              <li>
                <img src="/images/assets/home/technology5.png" alt="" />
                <h3>{t('gongshi2')}</h3>
                <span></span>
              </li>
              <li>
                <img src="/images/assets/home/technology6.png" alt="" />
                <h3>{t('gongshi3')}</h3>
                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div className="Main3">
          <div className="CNScoin">
            <h1>{t('sy9')}</h1>
            <p>{t('ssy1')}</p>
            <div>
              <ul>
                <li>{t('shouqifaxing')}</li>
                <div>
                  <p>{t('daibicns')}</p>
                  <p>{t('daibicns1')}</p>
                  <p>{t('daibicns2')}</p>
                  <p>{t('daibicns3')}</p>
                  <p>
                    {t('daibicns4')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('daibicns5')}
                  </p>
                  <p style={{ marginLeft: '5px' }}>{t('daibicns6')}</p>
                </div>
                <li>{t('daibicns7')}</li>
                <li style={{ color: '#00FFF4' }}>{t('daibicns8')}</li>
              </ul>
              <span>
                <img src="/images/assets/home/CNScoin.png" alt="" />
                <p className="cncoin_p1">94%</p>
                <p className="cncoin_p2">6%</p>
                {/* <p>{{ t("hoempc1") }}</p> */}
              </span>
            </div>
          </div>
          <div className="zoology">
            <h1>{t('hoempc2')}</h1>
            <ul>
              <li>
                <img src="/images/assets/home/zoologyItem1.png" alt="" />
                <span>
                  <p>{t('sss4')}</p>
                  <h5>CWallet</h5>
                </span>
              </li>
              <li>
                <img src="/images/assets/home/zoologyItem2.png" alt="" />
                <span>
                  <p>{t('sss5')}</p>
                  <h5>CTrade</h5>
                </span>
              </li>
              <li>
                <img src="/images/assets/home/zoologyItem3.png" alt="" />
                <span>
                  <p>{t('sss6')}</p>
                  <h5>CDID</h5>
                </span>
              </li>
              <li>
                <img src="/images/assets/home/zoologyItem4.png" alt="" />
                <span>
                  <p>{t('ss1')}</p>
                  <h5>CNFT</h5>
                </span>
              </li>
              {/* <li>
                <img src="/images/assets/home/zoologyItem5.png" alt="" />
                <span>
                  <p>{t("ss2")}</p>
                  <h5>CDEX</h5>
                </span>
              </li> */}
              <li>
                <img src="/images/assets/home/zoologyItem6.png" alt="" />
                <span>
                  <p>{t('ss3')}</p>
                  <h5>CGPT</h5>
                </span>
              </li>
              {/* <li>
                <img src="/images/assets/home/zoologyItem7.png" alt="" />
                <span>
                  <p>{t("ss4")}</p>
                  <h5>CLoan</h5>
                </span>
              </li> */}
              <li>
                <img src="/images/assets/home/zoologyItem8.png" alt="" />
                <span>
                  <p>{t('ss5')}</p>
                  <h5>NFTopia</h5>
                </span>
              </li>
              {/* <li>
                <img src="/images/assets/home/zoologyItem9.png" alt="" />
                <span>
                  <p>{t("ss6")}</p>
                  <h5>CB2B Connect</h5>
                </span>
              </li> */}
            </ul>
          </div>
          <div className="roadMap">
            <h1>{t('ss7')}</h1>
            <ul>
              <li>
                <span>
                  <p>{t('ss8')}</p>
                </span>
                <p>{t('ss9')}</p>
                <i>{t('ss10')}</i>
              </li>
              <li>
                <span>
                  <p>{t('syee2')}</p>
                </span>
                <p>{t('syee4')}</p>
                <i>{t('syee1')}</i>
              </li>
              <li>
                <span>
                  <p>{t('syee3')}</p>
                </span>
                <p>{t('hoempc4')}</p>
                <i>{t('syee5')}</i>
              </li>
              <li>
                <span>
                  <p>{t('syee8')}</p>
                </span>
                <p>{t('hoempc3')}</p>
                <i>{t('syee7')}</i>
              </li>
            </ul>
          </div>
          <div className="shareholder">
            <h4>{t('syee9')}</h4>
            <div className="major">
              <img src="/images/assets/home/shareHolderMajor1.png" alt="" />
              <img src="/images/assets/home/shareHolderMajor2.png" alt="" />
            </div>
            <div className="secMajor">
              <img src="/images/assets/home/secMajor1.png" alt="" />
              <img src="/images/assets/home/secMajor2.png" alt="" />
              <img src="/images/assets/home/secMajor3.png" alt="" />
              <img src="/images/assets/home/secMajor4.png" alt="" />
              <img src="/images/assets/home/secMajor5.png" alt="" />
              <img src="/images/assets/home/secMajor6.png" alt="" />
            </div>
            <div className="secMajor">
              <img src="/images/assets/home/secMajor7.png" alt="" />
              <img src="/images/assets/home/secMajor8.png" alt="" />
              <img src="/images/assets/home/secMajor9.png" alt="" />
              <img src="/images/assets/home/secMajor10.png" alt="" />
              <img src="/images/assets/home/secMajor11.png" alt="" />
              <img src="/images/assets/home/secMajor12.png" alt="" />
            </div>
            {/* <h4 className="report">{t("syeee")}</h4>
            <div className="scrollList"> */}
            {/* <vueSeamlessScroll :data="swiper" :className-option="classNameOption">
            <span className="swiper">
              <img
                v-for="(item, index) in swiper"
                :src="item"
                alt=""
                :key="index"
              />
            </span>
          </vueSeamlessScroll> */}
            {/* </div> */}
            <div className="footer">
              <div>
                <div className="footer_1">
                  <img src="/images/assets/header/logo.png" alt="" />
                  <p>Coinnexus</p>
                </div>
                <p>{t('syeee1')}</p>
              </div>
              <span>
                <img src="/images/assets/home/footer1.png" onClick={twitter} alt="" />
                <img src="/images/assets/home/footer2.png" onClick={medium} alt="" />
                <img src="/images/assets/home/footer3.png" onClick={feiji} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </IndexStyled>
  )
}

export default IndexPc
