import { IndexStyle } from './MbStyle'
import { useTranslation } from 'contexts/Localization'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const IndexMb = () => {
  const { t } = useTranslation()
  const twitter = () => {
    window.location.href = 'https://twitter.com/CoinNexus1986'
  }
  const medium = () => {
    window.location.href = 'https://medium.com/@coinnexusofficial'
  }
  const feiji = () => {
    window.location.href = 'https://t.me/CoinNexus1'
  }
  const router = useRouter()

  const navigate_to = (type:any) => {
    switch (type) {
      case 1:
        router.push('/swap')
        break
      case 2:
        window.location.href = 'https://scan.coinnexus.vip'
        break
      case 3:
        window.location.href = 'https://cnsbridge.coinnexus.vip'
        break
    }
  }

  const [fonstsize, setfontsize] = useState(0)
  const setRem = () => {
    const baseSize = 16
    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    const scaleBaseNumber = /Mobi|Android|iPhone/i.test(navigator.userAgent) ? 750 : 1920
    const scale = document.documentElement.clientWidth / scaleBaseNumber
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
    setfontsize(baseSize * Math.min(scale, 2))
  }
  useEffect(() => {
    // 初始化
    setRem()
    // 改变窗口大小时重新设置 rem
    window.onresize = function () {
      setRem()
    }
  }, [])
  return (
    <IndexStyle>
      <div className="outBox">
        <div className="Main">
          <div className="app1">
            <p className="app_1">COINNEXUS</p>
            <p className="app_2">{t('ylwebjryyz')}</p>
            <div className="app_3">
              <div className="app_3111" onClick={()=>navigate_to(1)}>
                <span>{t('dianjjinruswap')}</span>
                <img src="/images/assets/home/enterPoint.png" alt="" />
              </div>
              <div className="app_3111" onClick={()=>navigate_to(2)}>
                <span>{t('dianjjinrucns')}</span>
                <img src="/images/assets/home/enterPoint.png" alt="" />
              </div>
              <div className="app_3111" onClick={()=>navigate_to(3)}>
                <span>{t('dianjijinrukuanlq')}</span>
                <img src="/images/assets/home/enterPoint.png" alt="" />
              </div>
            </div>
          </div>
          <div className="app_4">
            <img src="/images/assets/homeyidond/app4@2x.png" alt="" />
            <p>{t('coinjianjie')}</p>
          </div>
          <p className="app_5">{t('coinqulkjinr')}</p>
          <div className="app_6">
            <p>{t('dinwei')}</p>
            <p>{t('ytweb3jrshdjv')}</p>
          </div>
          <div className="app_6 app6_top">
            <p>{t('smin')}</p>
            <p>{t('wjrjtggx')}</p>
          </div>
          <div className="app_6 app6_top">
            <p>{t('yjing')}</p>
            <p>{t('dazxnwxsfw')}</p>
          </div>
          <div className="app_7">
            <p>{t('aqjjh')}</p>
            <p>{t('djiscx')}</p>
          </div>
          <div className="app_8">
            <img src="/images/assets/homeyidond/app6@2x.png" alt="" />
            <div className="app8_da">
              <div className="app8_1">
                <img src="/images/assets/homeyidond/app9@2x.png" alt="" />
                <p>{t('kjill')}</p>
              </div>
              <div className="app8_1 app8_left">
                <img src="/images/assets/homeyidond/app10@2x.png" alt="" />
                <p>{t('qkl')}</p>
              </div>
              <div className="app8_1 app8_left">
                <img src="/images/assets/homeyidond/app11@2x.png" alt="" />
                <p>{t('lzjs')}</p>
              </div>
              <div className="app8_1">
                <img src="/images/assets/homeyidond/app12@2x.png" alt="" />
                <p>{t('rgzn')}</p>
              </div>
            </div>
          </div>
          <div className="app_7">
            <p>{t('jmizczc')}</p>
          </div>
          <div className="app_9">
            <div className="app9_1">
              <p className="app9_2">Andreessen Horowitz</p>
              <div className="app9_3">
                <p>{t('sjszda')}</p>
              </div>
            </div>
            <div className="app9_1">
              <p className="app9_2">TechConnect Partners</p>
              <div className="app9_3 app9_31">
                <p>{t('lingxdjjcx')}</p>
              </div>
            </div>
            <div className="app9_1">
              <p className="app9_2">Blockchain Capital</p>
              <div className="app9_3 app9_32">
                <p>{t('wymgjjs')}</p>
              </div>
            </div>
            <div className="app9_1 app9_hd">
              <p className="app9_2">Digital Assets Capital</p>
              <div className="app9_3 app9_33">
                <p>{t('zzuszzcly')}</p>
              </div>
            </div>
            <div className="app9_1 app9_hd1">
              <p className="app9_2">Innovation Ventures</p>
              <div className="app9_3 app9_34">
                <p>{t('zzyyklxm')}</p>
              </div>
            </div>
          </div>
          <div className="app_10">
            <p>{t('coinsdzlhz')}</p>
            <p>{t('xjpsxjpzzf')}</p>
          </div>
          <div className="app_11">
            <div className="app11_1">
              <p>{t('kejiyjr')}</p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('jlpzfw')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('kjinzfhhk')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('kjingduobzjy')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('szzfkfh')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('szsfyz')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('jingrpzfw')}</span>
              </p>
              <p>
                <img src="/images/assets/homeyidond/partnerTips.png" alt="" />
                <span>{t('zhinenghydk')}</span>
              </p>
            </div>
            <div className="app11_2">
              <img src="/images/assets/homeyidond/partner.png" alt="" />
            </div>
          </div>
          <div className="app_12">
            <p className="app12_1">{t('hxjs')}</p>
            <div className="app12_2">
              <img src="/images/assets/homeyidond/app13@2x.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">CM Virtual Machine</p>
                <p className="app12_5"></p>
              </div>
            </div>
            <div className="app12_2 topapp12">
              <img src="/images/assets/homeyidond/app14@2x.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">CoinNexus SDK</p>
                <p className="app12_5"></p>
              </div>
            </div>
            <div className="app12_2 topapp12">
              <img src="/images/assets/homeyidond/app15@2x.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">{t('gonshi')}</p>
                <p className="app12_5"></p>
              </div>
            </div>{' '}
            <div className="app12_2 topapp12">
              <img src="/images/assets/home/technology4.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">{t('gongshi1')}</p>
                <p className="app12_5"></p>
              </div>
            </div>{' '}
            <div className="app12_2 topapp12">
              <img src="/images/assets/home/technology5.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">{t('gongshi2')}</p>
                <p className="app12_5"></p>
              </div>
            </div>{' '}
            <div className="app12_2 topapp12">
              <img src="/images/assets/home/technology6.png" alt="" />
              <div className="app12_3">
                <p className="app12_4">{t('gongshi3')}</p>
                <p className="app12_5"></p>
              </div>
            </div>
          </div>
          <div className="app_10">
            <p>{t('sy9')}</p>
            <p>{t('ssy1')}</p>
          </div>
          <div className="app_13">
            <div className="app13_1">
              <div className="app13_2">
                <div className="app13_3">
                  <div className="app13_4">
                    <p>
                      <span></span>
                      {t('shouqifaxing')}
                    </p>
                    <p>
                      <span>{t('daibicns')}</span>
                      <span>{t('daibicns1')}</span>
                    </p>
                    <p>
                      <span>{t('daibicns2')}</span>
                      <span>{t('daibicns3')}</span>
                    </p>
                    <p>
                      <span>
                        {t('daibicns4')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('daibicns5')}
                      </span>
                      <span>{t('daibicns6')}</span>
                    </p>
                  </div>
                </div>
                <img src="/images/assets/home/CNScoin.png" alt="" />
              </div>
            </div>
            <div className="app13_5">
              <p>
                <span></span>
                {t('daibicns7')}
              </p>
            </div>
            <div className="app13_5">
              <p>
                <span></span>
                {t('daibicns8')}
              </p>
            </div>
          </div>
          <div className="app_14">
            <p className="app14_1">{t('sss3')}</p>
            <div className="app14_2">
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app17@2x.png" alt="" />
                <p>{t('sss4')}</p>
                <p>CWallet</p>
              </div>
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app18@2x.png" alt="" />
                <p>{t('sss5')}</p>
                <p>CTrade</p>
              </div>
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app19@2x.png" alt="" />
                <p>{t('sss6')}</p>
                <p>CDID</p>
              </div>
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app20@2x.png" alt="" />
                <p>{t('ss1')}</p>
                <p>CNFT</p>
              </div>
              {/* <div className="app14_3">
                <img src="/images/assets/homeyidond/app21@2x.png" alt="" />
                <p>{t('ss2')}</p>
                <p>CDEX</p>
              </div> */}
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app22@2x.png" alt="" />
                <p>{t('ss3')}</p>
                <p>CGPT</p>
              </div>
              {/* <div className="app14_3">
                <img src="/images/assets/homeyidond/app23@2x.png" alt="" />
                <p>{t('ss4')}</p>
                <p>CLoan</p>
              </div> */}
              <div className="app14_3">
                <img src="/images/assets/homeyidond/app24@2x.png" alt="" />
                <p>{t('ss5')}</p>
                <p>NFTopia</p>
              </div>
              {/* <div className="app14_3">
                <img src="/images/assets/homeyidond/app25@2x.png" alt="" />
                <p>{t('ss6')}</p>
                <p>CB2B Connect</p>
              </div> */}
            </div>
          </div>
          <div className="app_15">
            <p className="app15_1">{t('ss7')}</p>
            <div className="app15_2">
              <div className="app15_3">
                <p>
                  <span>{t('ss8')}</span>
                </p>
                <p></p>
                <p>{t('ss9')}</p>
                <p>{t('ss10')}</p>
              </div>
              <div className="app15_4">
                <p>{t('syee4')}</p>
                <p>{t('syee1')}</p>
                <p></p>
                <p>
                  <span>{t('syee2')}</span>
                </p>
              </div>
              <div className="app15_3 app15_leftd">
                <p>
                  <span>{t('syee3')}</span>
                </p>
                <p></p>
                <p>{t('hoempc4')}</p>
                <p>{t('syee5')}</p>
              </div>
              <div className="app15_4 app15_4left">
                <p>{t('hoempc3')}</p>
                <p>{t('syee7')}</p>
                <p></p>
                <p>
                  <span>{t('syee8')}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="app_16">
            <p className="app16_1">{t('syee9')}</p>
            <div className="app16_2">
              <img className="app16_3" src="/images/assets/homeyidond/app30@2x.png" alt="" />
              <img className="app16_4" src="/images/assets/homeyidond/app31@2x.png" alt="" />
            </div>
            <div className="app16_5">
              <div>
                <img className="app16_6" src="/images/assets/homeyidond/app32@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_7" src="/images/assets/homeyidond/app33@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_8" src="/images/assets/homeyidond/app34@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_9" src="/images/assets/homeyidond/app35@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_10" src="/images/assets/homeyidond/app36@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_11" src="/images/assets/homeyidond/app37@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_12" src="/images/assets/homeyidond/app38@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_13" src="/images/assets/homeyidond/app39@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_14" src="/images/assets/homeyidond/app40@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_15" src="/images/assets/homeyidond/app41@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_16" src="/images/assets/homeyidond/app42@2x.png" alt="" />
              </div>
              <div>
                <img className="app16_17" src="/images/assets/homeyidond/app43@2x.png" alt="" />
              </div>
            </div>
          </div>
          {/* <div className="app_17">
        <p className="app17_1">{{ t("syeee") }}</p>
        <div className="scrollList">
          <vueSeamlessScroll :data="swiper" :className-option="classOption">
            <span className="swiper">
              <img
                v-for="(item, index) in swiper"
                :src="item"
                alt=""
                :key="index"
              />
            </span>
          </vueSeamlessScroll>
        </div>
      </div> */}
          <div className="app_18">
            <div className="footer_1">
              <img src="/images/assets/header/logo.png" alt="" />
              <p>Coinnexus</p>
            </div>
            <p className="app18_2">{t('syeee1')}</p>
            <div className="app18_3">
              <img className="app18_4" onClick={twitter} src="/images/assets/homeyidond/app18_1@2x.png" alt="" />
              <img className="app18_4" onClick={medium} src="/images/assets/homeyidond/app18_2@2x.png" alt="" />
              <img className="app18_4" onClick={feiji} src="/images/assets/homeyidond/app18_3@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </IndexStyle>
  )
}
export default IndexMb
